import dynamic from 'next/dynamic';
import { FC, ReactNode } from 'react';

import { BulkErrorBoundary } from '@components/Alerts/ErrorBoundaryFallback';
import { Header } from '@components/Header';

type Props = {
  children: ReactNode;
  includeHeader?: boolean;
  includeFooter?: boolean;
};
// lazy load the footer since it is always below the fold for opt
const FooterDynamic = dynamic(() =>
  import('../../components/Footer/Footer').then((mod) => mod.Footer)
);

const StandardLayout: FC<Props> = ({
  children,
  includeHeader = true,
  includeFooter = true,
}) => (
  <>
    {includeHeader && <Header />}
    <BulkErrorBoundary location="Layout wrapper">{children}</BulkErrorBoundary>
    {includeFooter && <FooterDynamic />}
  </>
);

export { StandardLayout };
