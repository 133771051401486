import React, { ReactElement } from 'react';

import { Breadcrumbs } from '@components/Breadcrumbs';
import { AccountContainer as Container } from '@components/Container/AccountContainer';
import { Paragraph } from '@components/Typography/Paragraph/Paragraph';
import { TextLink } from '@components/Typography/TextLink/TextLink';

export function NotSignedIn(): ReactElement {
  return (
    <Container>
      <article className="mx-auto my-0 max-w-narrowPage px-2.5 py-0">
        <Breadcrumbs
          crumbs={[
            { title: 'Home', path: '/' },
            { title: 'Create Account', path: '/create-account' },
          ]}
        />
        <h1>Not signed in</h1>
        <Paragraph>
          You are trying to access a page that requires you to be{' '}
          <TextLink href="/login">signed in</TextLink> to an account.{' '}
        </Paragraph>
        <Paragraph>
          If the problem continues, please{' '}
          <TextLink href="/contact-us">get in touch</TextLink>.
        </Paragraph>
      </article>
    </Container>
  );
}
