/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable dot-notation */
import React, { ReactElement, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { useAddresses } from '@hooks/address/useAddresses';
import { useCsrfCookie } from '@hooks/useCsrfCookie';
import { Customer } from '@interfaces/Customer';

import { AccountSettingsFormFields } from '../../interfaces/IForms';
import { Button, ButtonType } from '../Button';
import { Input } from '../FormComponents';

interface Handler {
  (e: AccountSettingsFormFields): void;
}

export function AccountSettingsForm({
  customer,
  disabled,
  handleSubmit,
}: {
  customer: Customer;
  disabled: boolean;
  handleSubmit: Handler;
}): ReactElement {
  // get country for phone number
  const { addresses } = useAddresses();
  const [country, setCountry] = useState('AU');
  const csrfToken = useCsrfCookie();

  const rhfMethods = useForm<any>({
    mode: 'onTouched',
    defaultValues: {
      firstName: customer.firstName,
      lastName: customer.lastName,
      company: customer.company,
      phone: customer.phone,
      email: customer.email,
      country: country,
      password: '',
      confirm: '',
      current: '',
      csrfToken,
    },
  });

  useEffect(() => {
    if (addresses) {
      const countries = addresses.reduce(
        (acc, cur) => {
          acc[cur.country_code] += 1;
          return acc;
        },
        { AU: 0, NZ: 0 }
      );

      if (countries.AU < countries.NZ) {
        setCountry('NZ');
      } else {
        setCountry('AU');
      }
    }
  }, [addresses]);

  return (
    <div>
      <FormProvider {...rhfMethods}>
        <form
          className="flex flex-col gap-3 sm:grid sm:grid-cols-2 sm:gap-5"
          onSubmit={rhfMethods.handleSubmit(handleSubmit)}
        >
          <Input label="First Name" name="firstName" type="text" required />
          <Input label="Last Name" name="lastName" type="text" required />
          <Input label="Company" name="company" type="text" />
          <Input
            label="Phone Number"
            name="phone"
            type="text"
            required
            description="(Include area code for landline eg. 03 6266 4725)"
            onChange={async (e) => {
              const { value } = e.currentTarget;
              // Dynamically load libphonenumber-js
              const {
                isValidPhoneNumber,
                parsePhoneNumberWithError,
                ParseError,
              } = await import('libphonenumber-js/max');

              const formatPhoneNumber = (
                value: string,
                countryCode: string
              ): string => {
                try {
                  const phoneNumber = parsePhoneNumberWithError(
                    value,
                    countryCode === 'NZ' ? 'NZ' : 'AU'
                  );
                  if (phoneNumber) {
                    return phoneNumber.format('E.164');
                  }
                } catch (error) {
                  if (error instanceof ParseError) {
                    // Not a phone number, non-existent country, etc.
                    console.error(error.message);
                  }
                }
                return value;
              };

              if (
                !isValidPhoneNumber(
                  value,
                  rhfMethods.getValues('country') === 'NZ' ? 'NZ' : 'AU'
                )
              ) {
                rhfMethods.setError('phone', {
                  type: 'custom',
                  message: 'Phone number must be valid format',
                });
              }
              rhfMethods.setValue(
                'phone',
                formatPhoneNumber(
                  value,
                  rhfMethods.getValues('country') === 'NZ' ? 'NZ' : 'AU'
                ),
                { shouldValidate: false }
              );
            }}
          />
          <Input
            label="Email Address"
            name="email"
            type="text"
            required
            readOnly
          />
          <span />
          <h4 className="col-start-1 col-end-[span_2]">Change Password</h4>
          <Input
            label="Enter your new password"
            name="password"
            type="password"
            description="Passwords must be at least 12 characters long, with an uppercase letter, a lowercase letter, a number, and optionally any of these special characters: @$!%*#?&()"
          />
          <Input
            label="Confirm your new password"
            name="confirm"
            type="password"
            validate={(pwdValue: string) => {
              if (pwdValue !== rhfMethods.getValues('password'))
                return 'Passwords do not match.';
            }}
          />
          <Input
            label="Enter your current password"
            name="current"
            type="password"
            required
          />

          <div className="formbutton col-start-1 col-end-[span_2]">
            <Button
              type={ButtonType.submit}
              disabled={disabled || !rhfMethods.formState.isValid}
            >
              Update
            </Button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
}
