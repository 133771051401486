import dynamic from 'next/dynamic';
import { FC, ReactNode } from 'react';

import { BulkErrorBoundary } from '@components/Alerts/ErrorBoundaryFallback';
import { Header } from '@components/Header';

type Props = {
  children: ReactNode;
};

// lazy load to prioritise carousel
const FooterDynamic = dynamic(() =>
  import('../../components/Footer/Footer').then((mod) => mod.Footer)
);

const HomepageLayout: FC<Props> = ({ children }) => (
  <>
    <Header />
    <BulkErrorBoundary location="Home Page Layout">
      {children}
    </BulkErrorBoundary>
    <FooterDynamic />
  </>
);

export { HomepageLayout };
