import React, { CSSProperties, ReactElement } from 'react';

import { TextLink } from '@components/Typography/TextLink/TextLink';
import { mergeStyles } from '@lib/styles';

export function Breadcrumbs({
  crumbs,
  style,
  className,
}: {
  crumbs: { title: string; path: string }[];
  style?: CSSProperties;
  className?: string;
}): ReactElement {
  const lastIndex = crumbs.length - 1;
  return (
    <div
      itemScope
      itemType="https://schema.org/BreadcrumbList"
      style={style}
      className={mergeStyles(
        className ?? '',
        'bg-transparent py-1 pt-2.5 text-sm capitalize text-grey-dark dark:text-white'
      )}
    >
      {crumbs.map((crumb, idx) => (
        <span
          key={`breadcrumb${idx}`}
          itemProp="itemListElement"
          itemScope
          itemType="https://schema.org/ListItem"
        >
          <TextLink href={crumb.path} itemProp="item">
            <span itemProp="name" className="mx-2">
              {crumb.title}
            </span>
          </TextLink>
          <meta itemProp="position" content={(idx + 1).toString()} />
          {lastIndex > idx && <span>/</span>}
        </span>
      ))}
    </div>
  );
}
