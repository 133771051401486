import dynamic from 'next/dynamic';
import { FC, ReactNode } from 'react';

import { BulkErrorBoundary } from '@components/Alerts/ErrorBoundaryFallback';
import { Breadcrumbs } from '@components/Breadcrumbs';
import { Container } from '@components/Container';
import { Header } from '@components/Header';
import { RecentlyViewedProducts } from '@components/Product';

const ExitPreview = dynamic(() =>
  import('../../components/Button/ExitPreview').then((mod) => mod.ExitPreview)
);

const FooterDynamic = dynamic(() =>
  import('../../components/Footer').then((mod) => mod.Footer)
);

type Props = {
  children: ReactNode;
} & Record<string, any>;

const ProductLayout: FC<Props> = ({ children, ...props }) => (
  <>
    <Header />
    <BulkErrorBoundary location="ProductLayout">
      {props.isPreview && <ExitPreview />}
      <article id="top" className="group mt-0 dark:bg-black">
        <Container>
          <Breadcrumbs
            crumbs={props.breadcrumbs}
            className="mr-5 bg-white dark:bg-black md:w-[400px] lg:justify-self-end lg:!pl-0"
          />
        </Container>
        {children}
        {props.product && <RecentlyViewedProducts product={props.product} />}
      </article>
    </BulkErrorBoundary>
    <FooterDynamic />
  </>
);

export { ProductLayout };
