import { FC, ReactNode, useEffect, useState } from 'react';

import { AccountHeader, NotSignedIn } from '@components/Account';
import { BulkErrorBoundary } from '@components/Alerts/ErrorBoundaryFallback';
import { Loading } from '@components/Alerts/Loading';
import { Footer } from '@components/Footer';
import { Header } from '@components/Header';
import { useCustomer } from '@hooks/customer/useCustomer';
import { useLoadingState } from '@hooks/useLoadingState';
import { Stores, updateIndexedDBData } from '@lib/localData';
import { logBreadcrumb } from '@lib/utils';

type Props = {
  children: ReactNode;
};

const AccountLayout: FC<Props> = ({ children }) => {
  const { customer, isLoading } = useCustomer();
  const [loggedIn, setLoggedIn] = useState(false);
  const { isLoaded, stateLoaded } = useLoadingState(1);

  // Handle logout between tabs
  useEffect(() => {
    let mounted = true;
    // Handler to call on window resize
    function handleStorageEvent(event: StorageEvent) {
      if (event.key === 'logged_in') {
        if (mounted) setLoggedIn(event.newValue === 'true');
      }
    }
    // Add event listener
    window.addEventListener('storage', handleStorageEvent);
    // Remove event listener on cleanup
    return () => {
      mounted = false;
      window.removeEventListener('storage', handleStorageEvent);
    };
  }, []);

  // set logged_in to IndexedDB
  const setLocalLoggedIn = async () => {
    try {
      await updateIndexedDBData(
        Stores.keyvaluepairs,
        'logged_in',
        (customer !== null).toString()
      );
    } catch (err) {
      logBreadcrumb({
        category: 'IndexedDB',
        message: `Tried to set logged_in`,
        level: 'error',
      });
    }
  };

  // Customer changes, update login status
  useEffect(() => {
    let mounted = true;
    if (mounted && customer !== undefined) {
      setLoggedIn(customer !== null);
      setLocalLoggedIn();
    }
    if (mounted && !isLoading && !isLoaded()) {
      stateLoaded();
    }
    return function cleanup() {
      mounted = false;
    };
  }, [customer, stateLoaded, isLoading, isLoaded]);

  if (!isLoaded()) return <Loading />;

  return (
    <>
      <Header />
      {loggedIn ? (
        <>
          <BulkErrorBoundary location="Account Header">
            <AccountHeader />
            {children}
          </BulkErrorBoundary>
        </>
      ) : (
        <NotSignedIn />
      )}
      <Footer />
    </>
  );
};

export { AccountLayout };
