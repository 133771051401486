import useSWR from 'swr';

import { GetAddressesResponseData } from '@interfaces/Address';
import { BulkAPIErrorResponse } from '@interfaces/BulkAPI';
import { SWR_KEYS } from '@lib/constants';

const fetcher = async (url) => {
  const res = await fetch(url);

  if (res.status !== 200) {
    const {
      error: { message, code },
    } = (await res.json()) as BulkAPIErrorResponse;
    const error = new Error('Unable to fetch addresses');
    error.message = message.join('. ');
    throw error;
  }

  const { data: addresses } = await res.json();
  return addresses;
};

export function useAddresses(): {
  addresses: GetAddressesResponseData[] | null;
  isValidating: boolean;
  error: any;
} {
  const { data, error, isValidating } = useSWR<GetAddressesResponseData[]>(
    SWR_KEYS.addresses,
    fetcher,
    {
      revalidateOnFocus: false,
    }
  );

  const addresses = data ? data : null;

  return { addresses, error, isValidating };
}
