import { useReducer } from 'react';

export function useLoadingState(count: number) {
  const [stateToLoad, stateLoaded] = useReducer((state) => state - 1, count);

  const isLoaded = () => stateToLoad <= 0; // was === but in dev with hot reload it would always go negative

  return {
    isLoaded,
    stateLoaded,
  };
}
