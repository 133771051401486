/**
 * General tag component.
 */

import React, { ReactElement, ReactNode } from 'react';

export function Tag({
  children,
  onClick,
}: {
  children: React.ReactNode;
  onClick?: (event) => void;
}): ReactElement {
  return (
    <button
      type="button"
      onClick={onClick}
      className="cursor-pointer bg-grey-light p-1 text-xs uppercase text-grey-darkest hover:bg-orange hover:text-white dark:bg-grey-dark dark:text-white dark:hover:bg-orange"
    >
      {children}
    </button>
  );
}

const tagMap = {
  new: 'New',
  newFlavour: 'New Flavour',
  improvedFormula: 'Improved Flavour',
  limitedEdition: 'Limited Edition',
  limitedStock: 'Limited Stock',
  sellingFast: 'Selling Fast',
  vegan: 'Vegan',
  vegetarian: 'Vegetarian',
  hasta: 'HASTA',
  unsweetened: 'Unsweetened',
};

export function HighlightTag({
  tag,
  children,
}: {
  tag: keyof typeof tagMap;
  children: ReactNode;
}) {
  return (
    <span
      data-annotation={tag}
      className="absolute 
      -right-3 
      -top-2.5 
      rotate-10
      border
      border-solid
      border-white
      bg-[--bgColour]
      px-2
      py-[1px]
      text-[0.75rem] text-white
      shadow-[1px_1px_2px_var(--bgColour)]
      data-[annotation=hasta]:[--bgColour:theme(colors.blue.hasta)]
      data-[annotation=improvedFormula]:[--bgColour:theme(colors.green.tag)] data-[annotation=newFlavour]:[--bgColour:theme(colors.green.tag)]
      data-[annotation=new]:[--bgColour:theme(colors.green.tag)]
      data-[annotation=limitedEdition]:[--bgColour:theme(colors.orange.DEFAULT)] data-[annotation=limitedStock]:[--bgColour:theme(colors.orange.DEFAULT)] data-[tag=sellingFast]:[--bgColour:theme(colors.orange.DEFAULT)]
      data-[annotation=unsweetened]:[--bgColour:theme(colors.plant.DEFAULT)] data-[annotation=vegan]:[--bgColour:theme(colors.plant.DEFAULT)] data-[tag=vegetarian]:[--bgColour:theme(colors.plant.DEFAULT)]"
    >
      {children}
    </span>
  );
}
